import * as React from "react"
import styled from "styled-components"

import Layout from "../layout/Layout"
import Seo from "../components/Seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import CardImage from "../components/CardImage"

const StyledNotFoundPage = styled.main`
  .card-image {
    width: 70%;
    margin: 0 auto 4rem auto;
  }

  p {
    text-align: center;
  }
`

const NotFoundPage = () => (
  <Layout innerLayout>
    <Seo title="404: Not found" />
    <StyledNotFoundPage className="container">
      <Card>
        <CardImage className="card-image">
          <StaticImage
            src="../images/404-image.png"
            placeholder="tracedSVG"
            layout="constrained"
            alt="Persona confusa vicino ad una scritta 404"
          />
        </CardImage>

        <p>Ci dispiace ma questo indirizzo non è disponibile.</p>
        <p>
          <Link to="/">Torna all'home page.</Link>
        </p>
      </Card>
    </StyledNotFoundPage>
  </Layout>
)

export default NotFoundPage
