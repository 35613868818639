import React from "react"
import styled, { css } from "styled-components"
import respond from "../styles/abstracts/mediaqueries"

const StyledCardImage = styled.div`
  background: var(--white);
  grid-column: ${({ shortImage }) => (shortImage ? css`1 / -1` : css`default`)};
  border: 13px solid var(--white);
  box-sizing: border-box;
  box-shadow: 45.8202px 106.914px 183.281px rgba(27, 49, 66, 0.13);
  backdrop-filter: blur(10.6914px);
  border-radius: ${({ borderRadius }) =>
    borderRadius
      ? css`
          ${borderRadius}px
        `
      : css`35px`};
  margin: ${({ noMargin }) => (noMargin ? css`0` : css`4rem 0`)};

  ${respond(
    "phone-port",
    css`
      border: 8px solid var(--white);
      border-radius: 10px;
    `
  )}

  img {
    border-radius: ${({ borderRadius }) =>
      borderRadius
        ? css`
            ${borderRadius}px
          `
        : css`35px`};
    ${respond(
      "phone-port",
      css`
        border-radius: 10px;
      `
    )}
  }
`

const CardImage = props => {
  return (
    <StyledCardImage
      className={props.className || ""}
      noMargin={props.noMargin}
      borderRadius={props.borderRadius}
      shortImage={props.shortImage}
    >
      {props.children}
    </StyledCardImage>
  )
}

export default CardImage
